import { useEffect, useState } from 'react';
import { Input } from '../../common/Input/Input';
import * as schemas from '../../../assets/schemas/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as forms from '../../../assets/forms/forms';
import { ActionButton, AltButton } from '../../common/Buttons/Buttons';
import mocafiLogo from '../../../mocafiLogo.jpg';
import moment from 'moment';
import { cardholderService } from '../../../services/cardholderService';
import { municipalityService } from '../../../services/municipalityService';
import { notify } from '../../../assets/helpers/toast';
import { useNavigate } from 'react-router-dom';
import {
  clearObject,
  validateAge,
  formatPhoneNumber,
  exists,
} from '../../../assets/helpers/funciones';
import 'react-toastify/dist/ReactToastify.css';
import './DefaultPage.scss';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Spinner from '../../common/Spinner/Spinner';
import Header from '../Header/Header';
import Auth from '@aws-amplify/auth';

export const DefaultPage = (props) => {
  const history = useNavigate();
  const { route } = useAuthenticator((context) => [context.route]);
  const [isLoading, setIsloading] = useState(false);
  const [municipalityOptions, setMunicipalityOptions] = useState([]);
  const [formStage, setFormStage] = useState(1);
  const [municipality, setMunicipality] = useState('');
  const [cardholder, setCardholder] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schemas.PaymentSchema()),
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    setValue: setValue2,
    control: control2,
    watch: watch2,
    reset: reset2,
  } = useForm({
    resolver: yupResolver(schemas.FundSchema()),
  });

  const getMunicipalities = async () => {
    municipalityService
      .getMunicipalities()
      .then(async (response) => {
        if ([200, 201].includes(response.status)) {
          let municipalities = [];
          for (let i = 0; i < response?.data?.data.length; i++) {
            municipalities.push({
              _id: response?.data?.data[i]?._id,
              name: response?.data?.data[i]?.municipality,
            });
          }

          setMunicipalityOptions(municipalities);
          const user = await Auth.currentAuthenticatedUser();
          let municipality = user.attributes['custom:Municipality'];

          let [selectedMunicipality] = municipalities?.filter(
            (m) => m?.name === municipality
          );
          if (selectedMunicipality) {
            setMunicipality(selectedMunicipality);
          }
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };

  const onSubmit = handleSubmit(async (data) => {
    if (!validateAge(moment(data?.birthDate).format('YYYY-MM-DD'), 18)) {
      notify('User must be over 18 years old', 'info');
      return null;
    } else {
      let dataToPost = {
        firstName: data?.firstName,
        lastName: data?.lastName,
        dob: moment(data?.birthDate).format('YYYY-MM-DD'),
        email: data?.email.toLowerCase(),
        phone: data?.phone,
        householdId: data?.householdId,
        municipalProgramId: municipality?._id,
        clientId: data?.clientId,
        address: {
          state: data?.state,
          street: data?.street,
          city: data?.city,
          zipcode: data?.zipcode,
          apt: data?.apt,
        },
      };

      dataToPost.address = clearObject(dataToPost.address);
      dataToPost = clearObject(dataToPost);

      setCardholder(dataToPost);

      localStorage.setItem('cardholder', JSON.stringify(dataToPost));
      setFormStage(2);
    }
  });

  const onSubmit2 = handleSubmit2(async (data) => {
    let dataToPost = {
      ...cardholder,
      ...data,
    };

    if (Object.keys(dataToPost.address).length === 0) {
      delete dataToPost?.address;
    }

    if (!isLoading) {
      setIsloading(true);
      cardholderService
        .assignCard(dataToPost)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify('Card assigned', 'success');
            history(`/`);
            setFormStage(1);
            setCardholder('');
            reset();
            reset2();
            setIsloading(false);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
          console.log('error:', error);
        });
    }
  });

  useEffect(() => {
    getMunicipalities();
  }, []);

  if (route !== 'authenticated') {
    return <Spinner />;
  }

  if (formStage === 1) {
    return (
      <>
        <SubmitForm
          register={register}
          errors={errors}
          control={control}
          action={onSubmit}
        />{' '}
      </>
    );
  }
  if (formStage === 2) {
    return (
      <>
        <FundForm
          register={register2}
          errors={errors2}
          control={control2}
          back={() => setFormStage(1)}
          action={onSubmit2}
          cardholder={cardholder}
          options={{ municipalityOptions: municipalityOptions }}
        />{' '}
      </>
    );
  }
};

export const SubmitForm = (props) => {
  const { register, errors, control, action } = props;
  return (
    <div className="container">
      <Header />
      <div className="card">
        <div className="mocafi-logo-container">
          <img src={mocafiLogo} alt="mocafiLogo" />
        </div>
        <h3 style={{ textAlign: 'center' }}>Card Pickup</h3>
        <form className="form-container">
          <div className="row">
            {forms.newPayment(register, errors).map((l, i) => (
              <Input
                key={i}
                bootstrap={l.bootstrap}
                label={l.label}
                type={l.type}
                name={l.name}
                options={l?.options}
                register={l.register}
                placeholder={l.placeholder}
                errors={l.errors}
                control={control}
              />
            ))}
          </div>
          <div className="buttons-center-container">
            <ActionButton action={action} label={'Submit'} />
          </div>
        </form>
      </div>
    </div>
  );
};

export const FundForm = (props) => {
  const { register, errors, control, action, cardholder, options, back } =
    props;

  const { zipcode, apt, street, city, address } = cardholder?.address;

  return (
    <div className="container">
      <Header />
      <div className="card">
        <div className="mocafi-logo-container">
          <img src={mocafiLogo} alt="mocafiLogo" />
        </div>
        <h3 style={{ textAlign: 'center' }}>Assign Card</h3>
        <div className="summary-info-container">
          <p>
            <>
              Household ID: <b>{exists(cardholder?.householdId)}</b>
            </>
            <br />
            <>
              Client ID: <b>{exists(cardholder?.clientId)}</b>
            </>
            <br />
            <>
              First Name: <b>{exists(cardholder?.firstName)}</b>
            </>
            <br />
            <>
              Last Name: <b>{exists(cardholder?.lastName)}</b>
            </>
            <br />
            <>
              Date of Birth:
              <b>
                {'  ' + moment(exists(cardholder?.dob)).format('DD/MM/YYYY')}
              </b>
            </>
            <br />

            {cardholder?.phone && (
              <>
                Phone: <b> {formatPhoneNumber(exists(cardholder?.phone))}</b>{' '}
                <br />
              </>
            )}

            {cardholder?.email && (
              <>
                Email: <b> {exists(cardholder?.email)}</b>
                <br />
              </>
            )}

            {(zipcode || apt || street || city || address) && (
              <>
                Address:{' '}
                <b>
                  {exists(cardholder?.address?.apt) +
                    ' ' +
                    exists(cardholder?.address?.street) +
                    ' ' +
                    exists(cardholder?.address?.city) +
                    (cardholder?.address?.city ? ',' : '') +
                    ' ' +
                    exists(cardholder?.address?.state) +
                    (cardholder?.address?.state ? ',' : '') +
                    ' ' +
                    exists(cardholder?.address?.zipcode)}
                </b>
              </>
            )}

            <br />
          </p>
        </div>

        <form className="form-container">
          <div className="row">
            {/* <Input
              bootstrap={'col-12 col-lg-3'}
              label={'Municipality *'}
              type={'select'}
              name={'municipalProgramId'}
              options={options?.municipalityOptions}
              register={register}
              placeholder={''}
              errors={errors?.municipalProgramId?.message}
              control={control}
              disabled={true}
            /> */}
            <div className="col-12 col-md-2"></div>
            {forms.fundForm(register, errors).map((l, i) => (
              <Input
                key={i}
                bootstrap={l.bootstrap}
                label={l.label}
                type={l.type}
                name={l.name}
                options={l?.options}
                register={l.register}
                placeholder={l.placeholder}
                errors={l.errors}
                control={control}
              />
            ))}
            <div className="col-12 col-md-2"></div>
          </div>
          <div className="buttons-center-container">
            <AltButton action={back} label={'Return'} />
            <ActionButton action={action} label={'Assign'} />
          </div>
        </form>
      </div>
    </div>
  );
};
