import { toast } from 'react-toastify';
// toast.configure();

export const notify = (message, type) => {
  if (type === 'success') {
    toast.success(`${message}`, {
      position: toast.POSITION.TOP_RIGHT,
      // autoCLose: 3000,
      className: 'Ts-op',
    });
  }
  if (type === 'info') {
    toast.info(`${message}`, {
      position: toast.POSITION.TOP_RIGHT,
      className: 'Ts-op',
    });
  }
  if (type === 'error') {
    toast.error(`${message}`, {
      position: toast.POSITION.TOP_RIGHT,
      className: 'Ts-op',
    });
  }
};
