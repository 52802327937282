import { PWAStatus } from '../enums/enums';

export const currencyFormat = (value) => {
  if (!value && value != 0) {
    return '';
  }

  return '$' + value.toLocaleString('es');

  // value = Number(value).toFixed(2);
  // if (value < 0) {
  //   value = value * -1;
  //   return '-$' + value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // } else {
  //   return '$' + value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // }
};

export const capitalize = (str) => {
  if (str) {
    str = str?.toLowerCase();
    const arr = str.split(' ');
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(' ');
    return str2;
  } else return '';
};

export const exists = (str) => {
  if (str) {
    return str;
  } else return '';
};

export function format(inputDate) {
  if (inputDate) {
    // expects Y-m-d
    var splitDate = inputDate.split('-');
    if (splitDate.count == 0) {
      return null;
    }

    var year = splitDate[0];
    var month = splitDate[1];
    var day = splitDate[2];

    return day + '/' + month + '/' + year;
  }
}

export const fixResponseJSON = async (response) => {
  let rullResp = await response.json();
  rullResp.status = response.status;
  return rullResp;
};

export const filterParams = (params) => {
  for (const key in params) {
    if (params[key] === undefined || params[key] === '') {
      delete params[key];
    }
  }
  return params;
};

// Function that validates if the PWA is downloaded
export const isInstalled = () => {
  // For iOS
  if (window.navigator.standalone) return PWAStatus.IOS;

  // For Android
  if (window.matchMedia('(display-mode: standalone)').matches)
    return PWAStatus.ANDROID;

  // If neither is true, it's not installed
  return PWAStatus.BROWSER;
};

export const clearObject = (params) => {
  for (const key in params) {
    if (params[key] === undefined || params[key] === '') {
      delete params[key];
    }
  }
  return params;
};

export const validateAge = (date, minAge) => {
  let age = getAge(date);
  if (age < minAge) {
    return false;
  } else return true;
};

export const getAge = (DOB) => {
  var today = new Date();
  var birthDate = new Date(DOB);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}
