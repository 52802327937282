import { useEffect, useState } from 'react';
import Auth from '@aws-amplify/auth';
import { NYCPage } from '../NYCPage/NYCPage';
import { DefaultPage } from '../DefaultPage/DefaultPage';
import { municipalities } from '../../../assets/enums/enums';

export const Page = (props) => {
  const [municipality, setMunicipality] = useState('');

  const getMunicipalities = async () => {
    const user = await Auth.currentAuthenticatedUser();
    let response = user.attributes['custom:Municipality'];
    setMunicipality(response);
  };
  useEffect(() => {
    getMunicipalities();
  }, []);

  if (municipality === municipalities?.NYC_ASYLUM_SEEKERS) {
    return <NYCPage />;
  } else {
    return <DefaultPage />;
  }
};
