import { useState, useEffect } from 'react';
import { Input } from '../../common/Input/Input';
import * as schemas from '../../../assets/schemas/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Header from '../Header/Header';
import { ActionButton, AltButton } from '../../common/Buttons/Buttons';
import { cardholderService } from '../../../services/cardholderService';
import { municipalityService } from '../../../services/municipalityService';
import plusIcon from '../../../images/plus.svg';
import minusIcon from '../../../images/minus.svg';
import bigCheck from '../../../images/bigCheck.svg';
import alertImage from '../../../images/alert.svg';
import hexIcon from '../../../images/hex.svg';
import unselectedCheckbox from '../../../images/unselectedCheckbox.svg';
import selectedCheckbox from '../../../images/selectedCheckbox.svg';
import Auth from '@aws-amplify/auth';
import { notify } from '../../../assets/helpers/toast';
import moment from 'moment';
import { LoadSpinner } from '../../common/LoadSpinner/LoadSpinner';
import './NYCPage.scss';

const counterNames = {
  householdSize: 'householdSize',
  kidsUnderFive: 'kidsUnderFive',
  kidsAboveFive: 'kidsAboveFive',
  elegibleForSupplementalFunds: 'elegibleForSupplementalFunds',
};

export const NYCPage = () => {
  const [step, setStep] = useState(0);
  const [supplementalFunds, setSupplementalFunds] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [acknowledge, setAcknowledge] = useState(false);
  const [cards, setCards] = useState({
    number: 0,
    counter: 0,
    householdId: '',
    assignCardDto: [],
    assignedCards: [],
  });
  const [counter, setCounter] = useState({
    householdSize: 0,
    kidsUnderFive: 0,
    kidsAboveFive: 0,
    elegibleForSupplementalFunds: 0,
  });

  const [municipality, setMunicipality] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schemas.HouseHoldDetailSchema()),
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    control: control2,
    setError: setError2,
    reset: reset2,
    setValue: setValue2,
  } = useForm({
    resolver: yupResolver(schemas.handleCardSchema()),
  });

  const handleCounter = (name, action) => {
    const maxResidents = 8;
    // Max 8 residents allowed
    if (
      name === counterNames?.householdSize &&
      counter?.householdSize >= maxResidents &&
      action === 1
    ) {
      notify(`Max ${maxResidents} residents`, 'info');
      return null;
    }

    let kids = counter?.kidsUnderFive + counter?.kidsAboveFive + action;

    if (
      [counterNames?.kidsUnderFive, counterNames?.kidsAboveFive]?.includes(
        name
      ) &&
      kids >= counter?.householdSize
    ) {
      notify(`Max number of kids allowed`, 'info');
      return null;
    }

    // Cant have more childs/teenagers than people
    if (
      counter[name] + action === counter?.householdSize &&
      name !== counterNames?.elegibleForSupplementalFunds
    ) {
      notify('Quantity exceed number of residents', 'info');
      return null;
    }
    if (
      counter[name] + action === counter?.householdSize &&
      name === counterNames?.elegibleForSupplementalFunds
    ) {
      notify('Quantity exceed number of residents', 'info');
      return null;
    }

    setCounter((prevState) => {
      return {
        ...prevState,
        [name]: counter[name] + action >= 0 ? counter[name] + action : 0,
      };
    });

    if (
      name === counterNames?.householdSize &&
      counter?.householdSize + action === 0
    ) {
      setCounter((prevState) => {
        return {
          ...prevState,
          householdSize: 0,
          kidsUnderFive: 0,
          kidsAboveFive: 0,
          elegibleForSupplementalFunds: 0,
        };
      });
    }
  };

  const registerHouseholdDetails = handleSubmit(async (data) => {
    let household = { ...counter };
    setStep(step + 1);

    setCards((prevState) => {
      return {
        ...prevState,
        number:
          household?.householdSize -
          household?.kidsAboveFive -
          household?.kidsUnderFive,
        counter: 0,
        householdId: data?.householdId,
        assignCardDto: [],
      };
    });
  });

  const verifyCard = (cardLast4, uniqueCode) => {
    return cardholderService
      .verifyCard(cardLast4, uniqueCode)
      .then((response) => {
        if (response.status === 200 && response?.data?.data?.isCardAvailable) {
          // notify(`Valid Card`, 'success');
          return true;
        } else {
          // notify(`${response.message}` || 'error', 'error');
          setError2('cardLast4', {
            type: 'manual',
            message: 'Invalid card or Code',
          });
          setError2('uniqueCode', {
            type: 'manual',
            message: 'Invalid card or Code',
          });
          return false;
        }
      })
      .catch((error) => {});
  };

  const validateAge = (dob) => {
    // Get the current date
    const dateOfBirth = new Date(dob);
    const currentDate = new Date();
    const minAge = 18;
    // Calculate the minimum date for being 18 years old
    const minimumDate = new Date();
    minimumDate.setFullYear(currentDate.getFullYear() - minAge);
    minimumDate.setMonth(currentDate.getMonth());
    minimumDate.setDate(currentDate.getDate());

    // If the date of birth is before the minimum date, they are over 18
    if (dateOfBirth > minimumDate) {
      setError2('dob', {
        type: 'manual',
        message: `Primary Cardholder must be over ${minAge} years old`,
      });
    }
    return dateOfBirth <= minimumDate;
  };

  const setFistCardSupplemental = (assignedCards) => {
    let anySupplementalElegible = false;

    for (let i = 0; i < assignedCards?.length; i++) {
      if (assignedCards[i]?.isSupplementalElegible) {
        anySupplementalElegible = true;
      }
    }

    if (!anySupplementalElegible && counter?.elegibleForSupplementalFunds > 0) {
      assignedCards[0].isSupplementalElegible = true;
    }

    return assignedCards;
  };

  const fundCards = () => {
    if (!isLoading) {
      setIsLoading(true);
      cardholderService
        .fundCards({ data: cards?.assignedCards })
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            let success = true;
            for (let i = 0; i < response?.data?.data?.length; i++) {
              if (!response?.data?.data[i]?.status) {
                success = false;
              }
            }
            setIsLoading(false);
            if (success) {
              setStep(3);
            } else {
              setStep(4);
            }
          } else {
            notify(`${response.message}` || 'error', 'error');
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  const verifyClientId = (clientId) => {
    return cardholderService
      .verifyClientId(clientId)
      .then((response) => {
        if (response.status === 200) {
          if (!response?.data?.data?.clientIdExist) {
            return true;
          }
          if (response?.data?.data?.clientIdExist) {
            setError2('clientId', {
              type: 'manual',
              message: 'Invalid Client ID',
            });
            return false;
          }
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => {});
  };

  const handleCard = handleSubmit2(async (data) => {
    const validCard = await verifyCard(data?.cardLast4, data?.uniqueCode);
    const validClientId = await verifyClientId(data?.clientId);
    // const validAge = validateAge(data?.dob);

    if (validCard && validClientId) {
      let cardList = [...cards?.assignCardDto];
      if (cardList?.length === 0) {
        data.isPrimaryCard = true;
      } else {
        data.isPrimaryCard = false;
      }
      data.isSupplementalElegible = supplementalFunds;
      // data.dob = moment(data.dob)?.format('YYYY-MM-DD');
      data.municipalProgramId = municipality?._id;
      data.allocatedAmount = 0;
      cardList.push(data);

      // Validate if last card and change step
      if (cards?.counter + 1 >= cards?.number) {
        assignCard(cardList);
      } else {
        setCards((prevState) => {
          return {
            ...prevState,
            counter: cards?.counter + 1,
            assignCardDto: cardList,
          };
        });
        reset2();
        setSupplementalFunds(false);
      }
    }
  });

  const assignCard = (cardList) => {
    setIsLoading(true);

    if (cards?.counter + 1 == cards?.number) {
      setCards((prevState) => {
        return {
          ...prevState,
          counter: cards?.counter + 1,
          assignCardDto: cardList,
        };
      });
    }

    // Set is loading
    const data = {
      family: {
        assignCardDto:
          cards?.counter + 1 == cards?.number
            ? setFistCardSupplemental(cardList)
            : setFistCardSupplemental(cards?.assignCardDto),
        household: {
          householdId: cards?.householdId,
          ...counter,
        },
      },
    };
    cardholderService
      .assignCard(data)
      .then((response) => {
        if ([200, 201].includes(response.status)) {
          notify(`Cards assigned`, 'success');
          setSupplementalFunds(false);
          reset2();
          setIsLoading(false);
          setStep(step + 1);
          setCards((prevState) => {
            return {
              ...prevState,
              assignedCards: response?.data?.data?.assignedCards,
            };
          });
        } else {
          notify(`${response.message}` || 'error', 'error');
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const backStep = () => {
    // Return to cards form if in thelast card
    if (cards?.counter === cards?.number) {
      setStep(1);
      preloadForm();
      setSupplementalFunds(false);
    } else {
      preloadForm();
    }
  };

  const preloadForm = () => {
    // Get last card
    const selectedCard = cards?.assignCardDto[cards?.counter - 1];

    // Preload form
    if (selectedCard) {
      setValue2('clientId', selectedCard?.clientId);
      setValue2('firstName', selectedCard?.firstName);
      setValue2('lastName', selectedCard?.lastName);
      setValue2('lastName', selectedCard?.age);
      // setValue2('dob', new Date(selectedCard?.dob + 'T00:00:00'));
      setValue2('cardLast4', selectedCard?.cardLast4);
      setValue2('uniqueCode', selectedCard?.uniqueCode);
      setSupplementalFunds(selectedCard?.isSupplementalElegible);
    }

    cards?.assignCardDto?.pop();
    // Fall back one card
    if (cards?.counter - 1 >= 0) {
      setCards((prevState) => {
        return {
          ...prevState,
          counter: cards?.counter - 1,
          assignCardDto: cards?.assignCardDto,
        };
      });
    } else {
      setCards((prevState) => {
        return {
          ...prevState,
          counter: 0,
          assignCardDto: [],
        };
      });
      reset2();
      setStep(0);
    }
  };

  const cancelFunding = () => {
    reset();
    reset2();
    setStep(0);
    setAcknowledge(false);
    setCounter((prevState) => {
      return {
        ...prevState,
        householdSize: 0,
        kidsUnderFive: 0,
        kidsAboveFive: 0,
        elegibleForSupplementalFunds: 0,
      };
    });

    setCards((prevState) => {
      return {
        ...prevState,
        number: 0,
        counter: 0,
        householdId: '',
        assignCardDto: [],
        assignedCards: [],
      };
    });
  };

  const getMunicipalities = async () => {
    municipalityService
      .getMunicipalities()
      .then(async (response) => {
        if ([200, 201].includes(response.status)) {
          let municipalities = [];
          for (let i = 0; i < response?.data?.data.length; i++) {
            municipalities.push({
              _id: response?.data?.data[i]?._id,
              name: response?.data?.data[i]?.municipality,
            });
          }

          // setMunicipalityOptions(municipalities);
          const user = await Auth.currentAuthenticatedUser();
          let municipality = user.attributes['custom:Municipality'];

          let [selectedMunicipality] = municipalities?.filter(
            (m) => m?.name === municipality
          );
          if (selectedMunicipality) {
            setMunicipality(selectedMunicipality);
          }
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => {});
  };

  const handleSuplemental = (value) => {
    const newValue = !value;

    let supplementalCounter = 0;

    for (let i = 0; i < cards?.assignCardDto?.length; i++) {
      if (cards?.assignCardDto[i]?.isSupplementalElegible) {
        supplementalCounter = supplementalCounter + 1;
      }
    }

    if (newValue) {
      supplementalCounter = supplementalCounter + 1;
    }

    if (supplementalCounter > counter?.elegibleForSupplementalFunds) {
      notify(
        'You have exceeded the max number of supplemental funds eligible residents',
        'info'
      );
      return null;
    } else {
      setSupplementalFunds(newValue);
    }
  };

  useEffect(() => {
    getMunicipalities();
  }, []);

  return (
    <div>
      <LoadSpinner show={isLoading} />
      <Header programName={'NYC Asylum Seekers'} />
      <div className="flex flex-col min-h-screen items-center bg-gray-100">
        {step === 0 && (
          <HouseHoldDetailForm
            counter={counter}
            handleCounter={handleCounter}
            register={register}
            errors={errors}
            control={control}
            action={registerHouseholdDetails}
          />
        )}
        {step === 1 && (
          <CardForm
            register={register2}
            errors={errors2}
            control={control2}
            cards={cards}
            back={backStep}
            step={step}
            cancelFunding={cancelFunding}
            handleSuplemental={handleSuplemental}
            supplementalFunds={supplementalFunds}
            action={handleCard}
          />
        )}
        {step === 2 && (
          <ConfirmationForm
            action={fundCards}
            back={backStep}
            cards={cards}
            step={step}
            acknowledge={acknowledge}
            setAcknowledge={setAcknowledge}
            cancelFunding={cancelFunding}
          />
        )}
        {step === 3 && <SuccessCard action={cancelFunding} />}
        {step === 4 && <ErrorCard action={cancelFunding} />}
      </div>
    </div>
  );
};

export const CardForm = (props) => {
  const {
    register,
    control,
    errors,
    action,
    back,
    step,
    cards,
    cancelFunding,
    supplementalFunds,
    handleSuplemental,
  } = props;
  return (
    <>
      <WizardSteps step={step} cards={cards} />
      <div className="flex justify-center">
        <div className="bg-white shadow-md py-4 rounded-md">
          <h3 className="text-center mt-2 mb-4 font-bold">
            Card{' '}
            {cards?.counter !== cards?.number
              ? cards?.counter + 1
              : cards?.counter}{' '}
            {cards?.counter === 0 ? '(Primary)' : ''}
          </h3>
          <form className="form-container container">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="row">
                  <Input
                    bootstrap={'col-12'}
                    label={'Cient ID *'}
                    type={'text'}
                    name={'clientId'}
                    register={register}
                    placeholder={'Enter the client ID'}
                    errors={errors?.clientId?.message}
                    control={control}
                  />
                  <Input
                    bootstrap={'col-12'}
                    label={'Primary Cardholder First Name *'}
                    type={'text'}
                    name={'firstName'}
                    register={register}
                    placeholder={'Enter the first name'}
                    errors={errors?.firstName?.message}
                    control={control}
                  />
                  <Input
                    bootstrap={'col-12'}
                    label={'Primary Cardholder last Name *'}
                    type={'text'}
                    name={'lastName'}
                    register={register}
                    placeholder={'Enter the last name'}
                    errors={errors?.lastName?.message}
                    control={control}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="row">
                  <Input
                    bootstrap={'col-6 col-md-12'}
                    label={'Primary Cardholder age*'}
                    type={'number'}
                    name={'age'}
                    control={control}
                    register={register}
                    placeholder={'18'}
                    errors={errors?.age?.message}
                  />
                  <Input
                    bootstrap={'col-6 col-md-12'}
                    label={'Last 4 card digits*'}
                    type={'number'}
                    name={'cardLast4'}
                    register={register}
                    placeholder={'Enter last 4 digits'}
                    errors={errors?.cardLast4?.message}
                    control={control}
                  />
                  <Input
                    bootstrap={'col-12'}
                    label={'Emboss Line 2*'}
                    type={'text'}
                    name={'uniqueCode'}
                    register={register}
                    placeholder={'Enter emboss line 2 from the card'}
                    errors={errors?.uniqueCode?.message}
                    control={control}
                  />{' '}
                </div>
              </div>
            </div>
            <SupplementalFunding
              supplementalFunds={supplementalFunds}
              handleSuplemental={handleSuplemental}
            />
            <p
              className="cancel-funding-button"
              onClick={() => cancelFunding()}
            >
              Cancel Funding
            </p>
          </form>
          <div className="buttons-center-container">
            <AltButton action={() => back()} label={'Back'} />
            <ActionButton action={action} label={'Next'} />
          </div>
        </div>
      </div>
    </>
  );
};

export const HouseHoldDetailForm = (props) => {
  const { register, errors, control, action, counter, handleCounter } = props;
  return (
    <div className="nyc-card flex justify-center max-w-md ">
      <div className="bg-white shadow-md py-4 rounded-md">
        <h3 className="text-center mt-2 mb-4 font-bold">Household Details</h3>
        <form className="form-container">
          <div className="px-4">
            <div>
              <Input
                label={'Household ID*'}
                type={'text'}
                name={'householdId'}
                register={register}
                placeholder={'Enter the household ID'}
                errors={errors?.householdId?.message}
                control={control}
                className="h-12"
              />
            </div>
            <HouseHoldCounter
              text={'How many people reside at the residence?*'}
              name={counterNames?.householdSize}
              counter={counter?.householdSize}
              handleCounter={handleCounter}
            />
            <HouseHoldCounter
              text={'Number of kids under 5 years old*'}
              name={counterNames?.kidsUnderFive}
              counter={counter?.kidsUnderFive}
              handleCounter={handleCounter}
              disabled={counter?.householdSize === 0}
            />
            <HouseHoldCounter
              text={'Number of kids between 5-17 years old*'}
              name={counterNames?.kidsAboveFive}
              counter={counter?.kidsAboveFive}
              handleCounter={handleCounter}
              disabled={counter?.householdSize === 0}
            />
            <HouseHoldCounter
              text={'How many residents are eligible for supplemental funds*'}
              name={counterNames?.elegibleForSupplementalFunds}
              counter={counter?.elegibleForSupplementalFunds}
              handleCounter={handleCounter}
              disabled={counter?.householdSize === 0}
            />
            {counter?.householdSize > 0 && (
              <div className="buttons-center-container">
                <ActionButton action={action} label={'Next: Assign cards'} />
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export const HouseHoldCounter = (props) => {
  const { text, counter, name, handleCounter, disabled } = props;

  return (
    <div className="flex gap-x-4 my-4">
      <h6 className="text-lg font-semibold flex-1">{text}</h6>
      <div className="ml-auto flex gap-1 items-center">
        <img
          style={{ opacity: disabled ? 0.5 : 1 }}
          className="m-0 p-0 w-6 h-6 cursor-pointer"
          src={minusIcon}
          alt="minusIcon"
          onClick={
            disabled ? () => console.log('') : () => handleCounter(name, -1)
          }
        />
        <h6
          style={{ opacity: disabled ? 0.5 : 1 }}
          className="m-0 p-0 text-center"
        >
          {counter}
        </h6>
        <img
          style={{ opacity: disabled ? 0.5 : 1 }}
          className="m-0 p-0 w-6 h-6 cursor-pointer"
          src={plusIcon}
          alt="plusIcon"
          onClick={
            disabled ? () => console.log('') : () => handleCounter(name, +1)
          }
        />
      </div>
    </div>
  );
};

export const WizardSteps = (props) => {
  const { step, cards } = props;
  return (
    <div className="wizard-steps-container">
      <div>
        <p style={{ fontWeight: step >= 1 ? 'bold' : '' }}>Household Details</p>
        {step >= 1 && <img src={bigCheck} alt="bigCheck" />}
      </div>
      <div>
        <p
          style={{ fontWeight: cards?.counter >= cards?.number ? 'bold' : '' }}
        >
          Card {cards?.counter}/{cards?.number}
        </p>
        {cards?.counter >= cards?.number && (
          <img src={bigCheck} alt="bigCheck" />
        )}
      </div>
      <div>
        <p
          style={{ fontWeight: cards?.counter == cards?.number ? 'bold' : '' }}
        >
          Confirmation
        </p>
      </div>
    </div>
  );
};

export const ConfirmationForm = (props) => {
  const { action, step, cards, acknowledge, setAcknowledge, cancelFunding } =
    props;

  return (
    <>
      <WizardSteps step={step} cards={cards} />
      <div className="flex justify-center ">
        <div className="bg-white shadow-md py-4 rounded-md">
          <div className="container">
            <h3 style={{ textAlign: 'center' }}>Confirmation</h3>
            <h6>
              <b>Household ID:</b> {cards?.householdId}
            </h6>
            <div className="form-container">
              <div className="household-container">
                <div className="household-cards-container">
                  {cards?.assignedCards?.map((e, i) => (
                    <CardComponent key={e?.clientId} index={i} card={e} />
                  ))}
                </div>
                <div className="supplementalfunding-component">
                  {acknowledge && (
                    <img
                      src={selectedCheckbox}
                      alt="selectedCheckbox"
                      onClick={() => setAcknowledge(!acknowledge)}
                    />
                  )}
                  {!acknowledge && (
                    <img
                      src={unselectedCheckbox}
                      alt="unselectedCheckbox"
                      onClick={() => setAcknowledge(!acknowledge)}
                    />
                  )}
                  <p style={{ margin: '24px' }}>
                    I acknowledge that MoCaFi and Sunrise Banks, N.A. will share
                    my card-level spending data (e.g., Merchant Name,
                    Transaction Amount, Transaction Date/Time, etc.) with NYCHPD
                    and New York City staff and others are supporting NYCHPD for
                    program compliance, research and analysis purposes.
                  </p>
                </div>

                <div className="buttons-center-container">
                  {/* <AltButton action={() => back()} label={'Back'} /> */}
                  {acknowledge && (
                    <ActionButton action={() => action()} label={'Submit'} />
                  )}
                </div>
              </div>
              <p
                className="cancel-funding-button"
                onClick={() => cancelFunding()}
              >
                Cancel Funding
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const SuccessCard = (props) => {
  const { action } = props;

  return (
    <div className="success-message-container container bg-white shadow-md py-4 rounded-md">
      <img src={bigCheck} alt="bigCheck" />
      <h3 style={{ textAlign: 'center' }}>
        Cards successfully <br /> funded!
      </h3>

      <div className="buttons-center-container">
        <ActionButton action={() => action()} label={'Fund new cards'} />
      </div>
    </div>
  );
};

export const ErrorCard = (props) => {
  const { action } = props;

  return (
    <div className="success-message-container container bg-white shadow-md py-4 rounded-md">
      <img src={alertImage} alt="alertImage" />
      <h3 style={{ textAlign: 'center' }}>
        Something went wrong <br />
      </h3>
      <h5>Some cards were not funded</h5>
      <br />
      <div className="buttons-center-container">
        <ActionButton
          // action={() => action()}
          label={'Try Again'}
        />
      </div>
    </div>
  );
};

export const CardComponent = (props) => {
  const { card, index } = props;
  return (
    <div className="household-card-component">
      <div className="household-card-icon-container">
        <img src={hexIcon} alt="hexIcon" />
        <span></span>
      </div>
      <h1>{card?.firstName + ' ' + card?.lastName}</h1>
      <h2>$ {card?.allocatedAmount}</h2>
      {card?.isSupplementalElegible ? (
        <h3>
          <i>Supplemental funds eligible</i>
        </h3>
      ) : (
        <span style={{ height: '14px' }}></span>
      )}
      <div className="household-card-icon-container">
        {index === 0 ? <h4>Primary Card 1</h4> : <h4>Card {index + 1}</h4>}
        <span></span>
      </div>
    </div>
  );
};

export const SupplementalFunding = (props) => {
  const { supplementalFunds, handleSuplemental } = props;

  return (
    <div className="supplementalfunding-component">
      {supplementalFunds && (
        <img
          src={selectedCheckbox}
          alt="selectedCheckbox"
          onClick={() => handleSuplemental(supplementalFunds)}
        />
      )}
      {!supplementalFunds && (
        <img
          src={unselectedCheckbox}
          alt="unselectedCheckbox"
          onClick={() => handleSuplemental(supplementalFunds)}
        />
      )}
      <h6>Supplemental funds eligible</h6>
    </div>
  );
};
