import mocafiLogo from '../../mocafiLogo.jpg';
import appleLogo from '../../images/apple-Logo.png';
import androidLogo from '../../images/android-logo.png';
import chromeLogo from '../../images/chrome.png';
import { NavLink } from 'react-router-dom';

export const DownloadPage = () => {
  return (
    <>
      <div style={{ textAlign: 'center', height: '600px' }} className="card">
        <div className="mocafi-logo-container">
          <img src={mocafiLogo} alt="mocafiLogo" />
        </div>{' '}
        <h1 style={{ marginTop: '48px' }}>
          Welcome to the MoCaFi Community App!
        </h1>
        <h6 style={{ marginTop: '70px' }}>
          Click on your platform for installation instructions.
        </h6>
        <div className="operative-system-logo-container">
          <NavLink to="/browser-download">
            <img
              className="operative-system-logo"
              src={chromeLogo}
              alt="browserLogo"
            />
          </NavLink>
          <NavLink to="/apple-download">
            <img
              className="operative-system-logo"
              src={appleLogo}
              alt="appleLogo"
            />
          </NavLink>
          <NavLink to="/android-download">
            <img
              className="operative-system-logo"
              src={androidLogo}
              alt="androidLogo"
            />
          </NavLink>
        </div>
      </div>
    </>
  );
};
