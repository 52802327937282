export const allocatedAmount = [
  { _id: '100', name: '100 ' },
  { _id: '500', name: '500' },
  { _id: '1000', name: '1000' },
];

export const newPayment = (register, errors) => {
  return [
    {
      bootstrap: 'col-12 col-lg-4',
      label: 'Household ID *',
      type: 'text',
      name: 'householdId',
      register: register,
      placeholder: 'Household ID',
      errors: errors?.householdId?.message,
    },
    {
      bootstrap: 'col-12 col-lg-4',
      label: 'Client ID *',
      type: 'text',
      name: 'clientId',
      register: register,
      placeholder: 'Client ID',
      errors: errors?.clientId?.message,
    },
    {
      bootstrap: 'col-12 col-lg-4',
      label: 'First Name *',
      type: 'text',
      name: 'firstName',
      register: register,
      placeholder: 'First Name',
      errors: errors?.firstName?.message,
    },
    {
      bootstrap: 'col-12 col-lg-4',
      label: 'Last Name *',
      type: 'text',
      name: 'lastName',
      register: register,
      placeholder: 'Last Name',
      errors: errors?.lastName?.message,
    },
    {
      bootstrap: 'col-12 col-lg-4',
      label: 'Date of Birth *',
      type: 'date',
      name: 'birthDate',
      register: register,
      placeholder: '',
      errors: errors?.birthDate?.message,
    },
    {
      bootstrap: 'col-12 col-lg-4',
      label: 'Phone ',
      type: 'number',
      name: 'phone',
      register: register,
      placeholder: '',
      errors: errors?.phone?.message,
    },
    {
      bootstrap: 'col-12 col-lg-4',
      label: 'Email ',
      type: 'text',
      name: 'email',
      register: register,
      placeholder: 'example@gmail.com',
      errors: errors?.email?.message,
    },

    // {
    //   bootstrap: 'col-12 col-lg-4',
    //   label: 'Street ',
    //   type: 'text',
    //   name: 'street',
    //   register: register,
    //   placeholder: 'Street',
    //   errors: errors?.street?.message,
    // },
    // {
    //   bootstrap: 'col-12 col-lg-2',
    //   label: 'Apt. ',
    //   type: 'text',
    //   name: 'apt',
    //   register: register,
    //   placeholder: 'Apt',
    //   errors: errors?.apt?.message,
    // },
    // {
    //   bootstrap: 'col-12 col-lg-4',
    //   label: 'City ',
    //   type: 'text',
    //   name: 'city',
    //   register: register,
    //   placeholder: 'City',
    //   errors: errors?.city?.message,
    // },
    // {
    //   bootstrap: 'col-12 col-lg-4',
    //   label: 'State ',
    //   type: 'select',
    //   options: states,
    //   name: 'state',
    //   register: register,
    //   placeholder: 'State',
    //   errors: errors?.state?.message,
    // },
    // {
    //   bootstrap: 'col-12 col-lg-2',
    //   label: 'Zip Code ',
    //   type: 'text',
    //   options: states,
    //   name: 'zipcode',
    //   register: register,
    //   placeholder: 'Zip code',
    //   errors: errors?.zipcode?.message,
    // },
  ];
};

export const fundForm = (register, errors) => {
  return [
    {
      bootstrap: 'col-12 col-lg-3',
      label: 'Last 4 Card *',
      type: 'text',
      name: 'cardLast4',
      register: register,
      placeholder: 'xxxx',
      errors: errors?.cardLast4?.message,
    },
    {
      bootstrap: 'col-12 col-lg-3',
      label: 'Unique Code *',
      type: 'text',
      name: 'uniqueCode',
      register: register,
      placeholder: 'Unique Code',
      errors: errors?.uniqueCode?.message,
    },
    {
      bootstrap: 'col-12 col-lg-3',
      label: 'Amount *',
      type: 'number',
      name: 'allocatedAmount',
      register: register,
      placeholder: '',
      errors: errors?.allocatedAmount?.message,
    },
  ];
};

export const LoginForm = (register, errors) => {
  return [
    {
      bootstrap: 'col-12 ',
      label: 'Username',
      type: 'text',
      name: 'username',
      register: register,
      placeholder: '',
      errors: errors?.username?.message,
    },
    {
      bootstrap: 'col-12 ',
      label: 'Password',
      type: 'password',
      name: 'password',
      register: register,
      placeholder: '',
      errors: errors?.password?.message,
    },
  ];
};
