import React, { useState, useEffect, lazy, Suspense } from 'react';
import '@aws-amplify/ui-react/styles.css';
import { useLocation, Routes, Route } from 'react-router-dom';
import { RequireAuth } from './RequireAuth';
import { Login } from './components/views/Login/Login';
import { ActionButton } from './components/common/Buttons/Buttons';
import { isInstalled } from './assets/helpers/funciones';
import { AppleDownload } from './components/views/AppleDownload/AppleDownload';
import { AndroidDownload } from './components/views/AndroidDownload/AndroidDownload';
import { BrowserDownload } from './components/views/BrowserDownload/BrowserDownload';
import { PWAStatus } from './assets/enums/enums';
import './App.scss';
import Spinner from './components/common/Spinner/Spinner';
import { DownloadPage } from './components/views/Download';
import { Page } from './components/views/Page/page';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const NotFound = lazy(() => import('./components/views/NotFound'));

export const UpdateBanner = props => {
  const { showUpdateBtn, updateAction } = props;
  return (
    <div style={{ padding: showUpdateBtn ? '6px 6px' : '' }}>
      {showUpdateBtn ? <ActionButton label="Click to Update" action={() => updateAction()} /> : null}
      {/* <p>
        Version: {process.env.REACT_APP_VERSION || "development"}{" "}
        {" " + isInstalled()}
      </p> */}
    </div>
  );
};

function App(props) {
  const { appUpdatePending, updateAction } = props;
  const [showUpdateBtn, setShowUpdateBtn] = useState(appUpdatePending);
  const location = useLocation();

  // const location = useLocation();
  // React.useEffect(() => {
  //   if ('serviceWorker' in navigator) {
  //     navigator.serviceWorker.ready.then((registration) => {
  //       registration.update();
  //       console.log('UPDATE');
  //     });
  //   }
  // }, [location]);

  useEffect(() => {
    setShowUpdateBtn(appUpdatePending);
  }, [appUpdatePending]);

  if (
    ![PWAStatus.ANDROID, PWAStatus.IOS].includes(isInstalled()) &&
    process.env.REACT_APP_VERSION !== 'local' &&
    !['/apple-download', '/android-download', '/browser-download'].includes(location.pathname)
  ) {
    return (
      <>
        <UpdateBanner showUpdateBtn={showUpdateBtn} updateAction={updateAction} />
        <DownloadPage />
      </>
    );
  }

  return (
    <div className="App">
      <ToastContainer />
      <UpdateBanner showUpdateBtn={showUpdateBtn} updateAction={updateAction} />
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <Page />
              </RequireAuth>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/apple-download" element={<AppleDownload />} />
          <Route path="/android-download" element={<AndroidDownload />} />
          <Route path="/browser-download" element={<BrowserDownload />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
