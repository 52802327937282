import { instance } from './axios';
import Auth from '@aws-amplify/auth';
let url = process.env.REACT_APP_API_URL;

export const getCognitoToken = async () => {
  const token = await Auth.currentSession();
  return token.getIdToken().getJwtToken();
};

export const municipalityService = {
  getMunicipalities: async () => {
    let token = await getCognitoToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await instance
      .get(`${url}/v1/municipalities`, config)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        error.data = [];
        // error.message = error?.response?.data?.message;
        error.message = error?.response?.data?.error?.message;
        return error;
      });
    return response;
  },
};
